import React, { useContext, useState } from "react";
import { FootnoteSignature, Header, HeroContainer, Middle } from "./molecules";
import { IconButtonLink, SiteHeading, TimeRange } from "./atoms";
import { Footer, MobileLogin } from "./organisms";
import useSWR from "swr";
import { format, isToday } from "date-fns";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { APIContext } from "./App";
import { Alert, Button, Container, Spinner } from "react-bootstrap";

export const Notes = () => (
  <>
    <p className="text-secondary">
      SlotBooks makes managing your group classes quick and easy.
    </p>
    <p className="text-secondary">
      Are you a gym, teacher, instructor, or anyone else who needs to manage
      regular sessions + scheduling + billing for a list of clients?{" "}
      <a className="link-secondary" href="https://wa.me/message/4XJUNIA2VUKJC1">
        Get in touch
      </a>{" "}
      to see how SlotBooks can help make your life easier.
    </p>
  </>
);
export const Home = () => (
  <div>
    <Container>
      <SiteHeading>slot/books</SiteHeading>
    </Container>
    <HeroContainer>
      <MobileLogin />
    </HeroContainer>
    <Container>
      <Notes />
    </Container>
    <HeroContainer>
      <FootnoteSignature />
    </HeroContainer>
  </div>
);

export const LinedContainer = (props) => (
  <div
    className={classNames("bg-light border-top", {
      "border-bottom":
        props.progress === undefined ||
        props.progress === 0 ||
        isNaN(props.progress),
    })}
  >
    <Container
      className={classNames("d-flex justify-content-between gap-3 py-3", {
        "align-items-center": props.direction !== "column",
        "flex-column": props.direction === "column",
        "align-items-start": props.direction === "column",
      })}
    >
      {props.children}
    </Container>
    {isFinite(props.progress) && (
      <div className="progress rounded-0" style={{ height: "2px" }}>
        <div
          className="progress-bar bg-gradient bg-secondary opacity-75"
          role="progressbar"
          style={{ width: `${Math.min(props.progress * 100, 100)}%` }}
          aria-valuenow="10"
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    )}
  </div>
);

const DayIndicator = (props) => {
  return format(props.date, "EEEE");
};

const DateIndicator = (props) => {
  if (isToday(props.date)) {
    return "TODAY";
  }
  return (
    <>
      {format(props.date, "d")}
      <sup>{format(props.date, "do").slice(-2)}</sup>&nbsp;
      <span className="text-uppercase">{format(props.date, "MMM")}</span>
    </>
  );
};

export const Dashboard = () => {
  const { data } = useSWR("/assignments");

  return (
    <Scaffold>
      <Header>slot/books</Header>
      <Middle>
        {data.map((a) => {
          const start = new Date(a.start);
          return (
            <LinedContainer key={a.id}>
              <div className="d-flex flex-column justify-content-between flex-grow-1 gap-1">
                <div className="text-muted">
                  <span className="text-uppercase fw-bold">
                    <DayIndicator date={start} />
                  </span>
                  <span className="opacity-50">
                    &nbsp;/&nbsp;
                    <DateIndicator date={start} />
                  </span>
                </div>
                <div className="small fw-bold text-primary">
                  {a.calendar.name}
                </div>
              </div>
              <TimeRange start={a.start} finish={a.finish} />
              <IconButtonLink to={`/${a.calendar.slug}`} type="primary">
                <i className="bi bi-calendar2-week" />
              </IconButtonLink>
            </LinedContainer>
          );
        })}
      </Middle>
      <Footer />
    </Scaffold>
  );
};

const SlotAvailabilityIndicator = (props) => {
  if (props.slot.booked) {
    return <span className="text-primary fw-bold">BOOKED</span>;
  }
  if (props.slot.limited) {
    return <span className="text-danger">LIMITED</span>;
  }
  if (props.slot.fullness < 1) {
    return <span className="text-success">AVAILABLE</span>;
  }
  return <span className="text-secondary">FULL</span>;
};

export const IconButton = (props) => (
  <button
    disabled={props.disabled}
    onClick={props.onClick}
    className={classNames("btn shadow-sm bg-gradient", {
      "btn-primary": props.type === "primary",
      "btn-secondary": props.type === "secondary",
      "btn-danger": props.type === "danger",
      "btn-success": props.type === "success",
    })}
  >
    {props.children}
  </button>
);
const SlotActionIcon = (props) => {
  const api = useContext(APIContext);
  const [running, setRunning] = useState(false);
  const runningWrap = async (operation) => {
    setRunning(true);
    await props.onChange(operation.then((r) => r.data));
    setRunning(false);
  };
  const book = async () => {
    await runningWrap(api.post("/assignments", { slot_id: props.slot.id }));
  };
  const cancel = async () => {
    await runningWrap(api.delete(`/assignments/${props.slot.assignment_id}`));
  };
  if (props.slot.booked) {
    return (
      <Button
        variant="danger"
        className="bg-gradient"
        onClick={cancel}
        disabled={props.disabled}
      >
        {running ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <span className="">&#x274C;</span>
        )}
      </Button>
    );
  }
  if (props.slot.fullness < 1) {
    return (
      <Button
        variant="success"
        onClick={book}
        disabled={props.disabled}
        className="bg-gradient"
      >
        {running ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <span className="">&#x2795;</span>
        )}
      </Button>
    );
  }
  return (
    <Button variant="secondary" disabled={true} className="bg-gradient">
      <i className="bi bi-grid-3x3-gap-fill" />
    </Button>
  );
};

export const makeDateTimeFromWallClock = (wc) =>
  new Date(2021, 1, 1, wc.hour, wc.minute);

export const ScheduleDisplay = (props) => (
  <>
    <div className="small fw-bold text-dark">
      {props.schedule.weekdays.map((d) => d.slice(0, 3)).join(" / ")}
    </div>
    <TimeRange
      start={makeDateTimeFromWallClock(props.schedule.start)}
      finish={makeDateTimeFromWallClock(props.schedule.finish)}
    />
  </>
);

export const MembershipInfo = (props) => (
  <div className="d-flex flex-column gap-2 border-bottom border-3 border-top">
    {props.data.expiry && (
      <LinedContainer>
        <div className="small text-muted text-center flex-grow-1">
          Active until {format(new Date(props.data.expiry), "do MMMM, yyyy")}.
        </div>
      </LinedContainer>
    )}
    {props.data.schedules.map((schedule) => (
      <LinedContainer key={schedule.id}>
        <ScheduleDisplay schedule={schedule} />
      </LinedContainer>
    ))}
  </div>
);

const InfoButton = (props) => (
  <Button variant="primary" size="sm" onClick={props.onClick}>
    <i className="bi bi-person" />
  </Button>
);

export const BackHomeButton = (props) => (
  <Link to={props.to || "/"} className="fs-2">
    <i className="bi bi-arrow-bar-left" />
  </Link>
);

const Scaffold = (props) => {
  return (
    <div className="d-flex flex-column justify-content-between min-vh-100">
      {props.children}
    </div>
  );
};

export const Slots = () => {
  const { slug } = useParams();
  const slotsSWR = useSWR(`/calendars/${slug}/slots`);
  const calendarSWR = useSWR(`/calendars/${slug}`);
  const [running, setRunning] = useState(false);
  const onChange = (dataPromise) => {
    setRunning(true);
    return slotsSWR.mutate(dataPromise, false).then(() => setRunning(false));
  };
  const [showInfo, setShowInfo] = useState(false);
  return (
    <Scaffold>
      <Header>
        <BackHomeButton />
        <div className="flex-grow-1 text-dark">{calendarSWR.data.name}</div>
        <InfoButton
          onClick={() => setShowInfo(!showInfo)}
          showInfo={showInfo}
        />
      </Header>
      <Middle>
        {showInfo && <MembershipInfo data={calendarSWR.data} />}
        {slotsSWR.data.map((day) => {
          const dt = new Date(day.date);
          return (
            <LinedContainer key={dt}>
              <div className="d-flex flex-column flex-grow-1 gap-4">
                <div className="d-flex flex-row gap-3 justify-content-between align-items-baseline">
                  <span className="text-uppercase text-muted fw-bold fs-6">
                    <DayIndicator date={dt} />
                  </span>
                  <span className="opacity-50 small">
                    <DateIndicator date={dt} />
                  </span>
                </div>
                <div className="row row-cols-2 g-3">
                  {day.slots.map((s) => (
                    <div className="col" key={s.id}>
                      <div
                        className={classNames(
                          "d-flex flex-row align-items-center gap-2 border p-3 rounded bg-white position-relative justify-content-between",
                          { "border-primary": s.booked }
                        )}
                      >
                        <span
                          className={classNames(
                            "position-absolute small bg-gradient bg-light px-1 rounded border",
                            { "border-primary": s.booked }
                          )}
                          style={{ top: "-0.6rem", left: "0.318rem" }}
                        >
                          <SlotAvailabilityIndicator slot={s} />
                        </span>
                        <TimeRange start={s.start} finish={s.finish} />
                        <div className="align-self-center">
                          <SlotActionIcon
                            slot={s}
                            onChange={onChange}
                            disabled={
                              running || s.late || (!s.booked && s.limited)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </LinedContainer>
          );
        })}
      </Middle>
      <Footer />
    </Scaffold>
  );
};
