import React, { Suspense, useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import "./hco_fonts/hco_fonts.css";
import { useLocalStorage } from "./hooks";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import {
  BackHomeButton,
  Dashboard,
  Home,
  LinedContainer,
  makeDateTimeFromWallClock,
  ScheduleDisplay,
  Slots,
} from "./pages";
import axios from "axios";
import useSWR, { SWRConfig } from "swr";
import useSWRInfinite from "swr/infinite";
import { DateIndicator, Header, Middle, RatioDisplay } from "./molecules";
import {
  DeleteConfirmationModal,
  Footer,
  PersonForm,
  PersonSelector,
  PrimaryToast,
} from "./organisms";
import { IconButtonLink, TimeRange } from "./atoms";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { Button, Spinner } from "react-bootstrap";
import classnames from "classnames";
import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

export const APIContext = React.createContext(null);
export const LoginContext = React.createContext(null);
export const LogoutContext = React.createContext(null);

const appsignal = new Appsignal({
  key: process.env.REACT_APPSIGNAL_TOKEN,
});

const AdminCalendars = () => {
  const { data } = useSWR("/admin/calendars");
  return (
    <>
      <Header>Calendars</Header>
      <Middle>
        {data.map((c) => (
          <LinedContainer key={c.id}>
            <LineHeading>{c.name}</LineHeading>
            <IconButtonLink to={`/admin/calendars/${c.slug}`}>
              <i className="bi bi-shuffle" />
            </IconButtonLink>
          </LinedContainer>
        ))}
      </Middle>
      <Footer />
    </>
  );
};

function LineHeading(props) {
  return (
    <div className="fw-bold text-secondary text-uppercase lh-1">
      {props.children}
    </div>
  );
}

const LineIndicators = (props) => (
  <div className="small text-muted d-flex flex-row gap-2 flex-wrap">
    {props.children}
  </div>
);

const LineIndicator = (props) => (
  <span className="border p-1 px-2 rounded bg-gradient bg-white">
    {props.children}
  </span>
);

function LineIndicatorSection(props) {
  return <div className="d-flex flex-column gap-3">{props.children}</div>;
}

const AdminCalendar = () => {
  const { slug } = useParams();
  const { data } = useSWR(`/admin/calendars/${slug}`);
  return (
    <>
      <Header>
        <BackHomeButton to="/admin/calendars" />
        <div className="flex-grow-1 text-dark">{data.name}</div>
      </Header>
      <Middle>
        <LinedContainer progress={data.slots.assignments / data.slots.capacity}>
          <LineIndicatorSection>
            <LineHeading>Slots</LineHeading>
            <LineIndicators>
              <LineIndicator>{data.slots.upcoming} open</LineIndicator>
              <LineIndicator>
                <RatioDisplay
                  num={data.slots.assignments}
                  denom={data.slots.capacity}
                />{" "}
                booked
              </LineIndicator>

              {data.slots.assignments + data.slots.capacity > 1 ? (
                <LineIndicator>
                  {Math.round(
                    (data.slots.assignments * 100) / data.slots.capacity
                  )}
                  % full
                </LineIndicator>
              ) : (
                <></>
              )}
            </LineIndicators>
          </LineIndicatorSection>
          <IconButtonLink
            to={`/admin/calendars/${slug}/slots`}
            icon="calendar-range"
          />
        </LinedContainer>
        <LinedContainer
          progress={data.schedules.people / data.schedules.capacity}
        >
          <LineIndicatorSection>
            <LineHeading>Schedules</LineHeading>
            <LineIndicators>
              <LineIndicator>{data.schedules.count} open</LineIndicator>
              <LineIndicator>{data.schedules.slots} slots</LineIndicator>
              <LineIndicator>
                <RatioDisplay
                  num={data.schedules.people}
                  denom={data.schedules.capacity}
                />{" "}
                booked
              </LineIndicator>
              <LineIndicator>
                {Math.round(
                  (data.schedules.people * 100) / data.schedules.capacity
                ) || 0}
                % full
              </LineIndicator>
            </LineIndicators>
          </LineIndicatorSection>
          <IconButtonLink
            to={`/admin/calendars/${slug}/schedules`}
            icon="calendar3"
          />
        </LinedContainer>
        <LinedContainer>
          <LineIndicatorSection>
            <LineHeading>People</LineHeading>
            <LineIndicators>
              <LineIndicator>{data.people.count} people</LineIndicator>
            </LineIndicators>
          </LineIndicatorSection>
          <IconButtonLink
            to={`/admin/calendars/${slug}/people`}
            icon="people-fill"
          />
        </LinedContainer>
        <LinedContainer>
          <LineIndicatorSection>
            <LineHeading>History</LineHeading>
          </LineIndicatorSection>
          <IconButtonLink
            to={`/admin/calendars/${slug}/history`}
            icon="list-columns-reverse"
          />
        </LinedContainer>
      </Middle>
      <Footer />
    </>
  );
};

const NameIndicator = (props) => (
  <span className="small border px-2 py-1 fw-bold rounded-1 text-dark bg-white shadow-sm">
    {props.name}
  </span>
);

const CalendarDetailsBar = (props) => {
  const { slug } = useParams();
  const { data } = useSWR(`/admin/calendars/${slug}`);
  return (
    <>
      <BackHomeButton to={`/admin/calendars/${slug}`} />
      <div className="flex-grow-1 text-primary">{props.heading}</div>
      <div className="text-dark small">{data.name}</div>
    </>
  );
};

const AdminScheduleDisplay = (props) => (
  <LinedContainer
    progress={props.schedule.assignments.length / props.schedule.capacity}
  >
    <TimeRange
      start={makeDateTimeFromWallClock(props.schedule.start)}
      finish={makeDateTimeFromWallClock(props.schedule.finish)}
    />
    <div className="d-flex gap-2 flex-wrap flex-grow-1 justify-content-center">
      {props.schedule.weekdays.map((wday) => (
        <span key={wday} className="badge bg-light text-dark border shadow-sm ">
          {wday.slice(0, 3)}
        </span>
      ))}
    </div>
    <RatioDisplay
      num={props.schedule.assignments.length}
      denom={props.schedule.capacity}
      className="fs-5"
    />
    <Link to={`/admin/schedules/${props.schedule.id}`}>
      <Button variant="warning">
        <i className="bi bi-shuffle" />
      </Button>
    </Link>
  </LinedContainer>
);

const AdminSchedules = () => {
  const { slug } = useParams();
  const { data } = useSWR(`/admin/calendars/${slug}/schedules`);
  return (
    <>
      <Header>
        <CalendarDetailsBar heading={"Schedules"} />
      </Header>
      <Middle>
        {data.map((schedule) => (
          <AdminScheduleDisplay key={schedule.id} schedule={schedule} />
        ))}
      </Middle>
      <Footer />
    </>
  );
};

const AdminSlots = () => {
  const { slug } = useParams();
  const { data } = useSWR(`/admin/calendars/${slug}/slots`);
  return (
    <>
      <Header>
        <CalendarDetailsBar heading="Slots" />
      </Header>
      <Middle>
        {data.map((s) => (
          <LinedContainer
            key={s.id}
            progress={s.assignments.length / s.capacity}
          >
            <div className="d-flex gap-3 flex-column flex-grow-1">
              <div className="d-flex gap-3 flex-row align-items-center justify-content-between">
                <TimeRange start={s.start} finish={s.finish} />
                <DateIndicator
                  date={s.start}
                  className="text-center flex-grow-1"
                />
                <RatioDisplay
                  num={s.assignments.length}
                  denom={s.capacity}
                  className="fs-5"
                />
                <Link to={`/admin/slots/${s.id}`}>
                  <Button variant="warning">
                    <i className="bi bi-shuffle" />
                  </Button>
                </Link>
              </div>
              <div className="d-flex flew-row flex-wrap gap-2 border-top pt-3">
                {s.assignments.map((a) => (
                  <NameIndicator key={a.id} name={a.name} />
                ))}
              </div>
            </div>
          </LinedContainer>
        ))}
      </Middle>
      <Footer />
    </>
  );
};
const Event = ({ event }) => {
  return (
    <LinedContainer direction="column">
      <div className="d-flex justify-content-between w-100">
        <span className="fw-bold">
          {format(new Date(event.timestamp), "hh:mmaaa")}
        </span>
        <span className="fw-light">
          {format(new Date(event.timestamp), "EEE, do MMM yy")}
        </span>
      </div>
      <div>{event.message}</div>
    </LinedContainer>
  );
};

const AdminHistory = () => {
  const { slug } = useParams();
  const { data, size, setSize } = useSWRInfinite((index, prevData) => {
    if (index === 0) return `/admin/calendars/${slug}/events`; // first page
    return `/admin/calendars/${slug}/events?after=${
      prevData[prevData.length - 1].id
    }`;
  });
  console.log(data);
  return (
    <>
      <Header>
        <CalendarDetailsBar heading="History" />
      </Header>
      <Middle>
        {data.map((events) =>
          events.map((event) => <Event key={event.id} event={event} />)
        )}
        {data[data.length - 1].length ? (
          <LinedContainer>
            <Button
              className="w-100 text-uppercase"
              onClick={() => setSize(size + 1)}
            >
              Load More
            </Button>
          </LinedContainer>
        ) : (
          <></>
        )}
      </Middle>
      <Footer />
    </>
  );
};

const AdminPeople = () => {
  const { slug } = useParams();
  const { data, mutate } = useSWR(`/admin/calendars/${slug}/people`);
  const api = useContext(APIContext);
  const [addingNewPerson, setAddingNewPerson] = useState(false);
  return (
    <>
      <Header>
        <CalendarDetailsBar heading="People" />
      </Header>
      <Middle>
        <LinedContainer>
          <div className="fw-bold text-muted">
            {data.length} {data.length === 1 ? "person" : "people"} registered.
          </div>
          <Button
            variant="success"
            onClick={() => setAddingNewPerson(!addingNewPerson)}
          >
            <i
              className={classnames("bi", {
                "bi-plus-lg": !addingNewPerson,
                "bi-x-lg": addingNewPerson,
              })}
            />
          </Button>
        </LinedContainer>
        {addingNewPerson && (
          <LinedContainer>
            <PersonForm
              data={{}}
              mutate={mutate}
              autoClear={true}
              action={(newPerson) =>
                api.post(`/admin/calendars/${slug}/people`, newPerson)
              }
            />
          </LinedContainer>
        )}

        {data.map((p) => (
          <LinedContainer key={p.id}>
            <div>
              <div className="fs-6 fw-bold">{p.name}</div>
              <a
                className="small text-decoration-none text-muted opacity-75"
                href={`tel:${p.phone}`}
              >
                {p.phone}
              </a>
              {p.expiry && (
                <div className="small">
                  {p.expired && (
                    <span className="text-danger">
                      Expired {formatDistanceToNow(parseISO(p.expiry))} ago
                    </span>
                  )}
                  {!p.expired && (
                    <span className="text-success">
                      Expires in {formatDistanceToNow(parseISO(p.expiry))}
                    </span>
                  )}
                </div>
              )}
            </div>
            <Link to={`/admin/people/${p.id}`}>
              <Button variant="warning">
                <i className="bi bi-person-badge" />
              </Button>
            </Link>
          </LinedContainer>
        ))}
      </Middle>
      <Footer />
    </>
  );
};

const AdminSchedule = () => {
  const { id } = useParams();
  const api = useContext(APIContext);
  const { data, mutate } = useSWR(`/admin/schedules/${id}`);
  const [personToDelete, setPersonToDelete] = useState(null);
  const [running, setRunning] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const handlePersonChange = (e) => setSelectedPersonId(e.target.value);
  const [toastMessage, setToastMessage] = useState(null);
  const createAssignment = async () => {
    setRunning(true);
    const createdAssignment = await api.post(
      `/admin/schedules/${id}/scheduled_assignments`,
      {
        person: selectedPersonId,
      }
    );
    await mutate();
    setRunning(false);
    setSelectedPersonId(null);
    setToastMessage(
      `${createdAssignment.data.name} was assigned to this schedule.`
    );
  };
  const deleteAssignment = async () => {
    setRunning(true);
    const deletedAssignment = await api.delete(
      `/admin/scheduled_assignments/${personToDelete.id}`
    );
    await mutate();
    setPersonToDelete(null);
    setRunning(false);
    setToastMessage(
      `${deletedAssignment.data.name} was removed from this schedule.`
    );
  };
  return (
    <>
      <Header>
        <BackHomeButton
          to={`/admin/calendars/${data.calendar.slug}/schedules`}
        />
        <div className="d-flex gap-2 flex-wrap flex-grow-1 justify-content-center">
          {data.weekdays.map((wday) => (
            <span className="badge bg-light text-dark border shadow-sm ">
              {wday.slice(0, 3)}
            </span>
          ))}
        </div>
        <TimeRange
          start={makeDateTimeFromWallClock(data.start)}
          finish={makeDateTimeFromWallClock(data.finish)}
        />
      </Header>
      <Middle>
        {data.assignments.map((a) => (
          <LinedContainer key={a.id}>
            <div className="fs-6">{a.name}</div>
            <Button
              variant="danger"
              onClick={() => setPersonToDelete(a)}
              disabled={running}
            >
              <i className="bi bi-x-lg" />
            </Button>
          </LinedContainer>
        ))}
        <PersonSelector
          person={selectedPersonId}
          onChange={handlePersonChange}
          people={data.people}
          onSubmit={createAssignment}
          running={running}
        />
        <DeleteConfirmationModal
          thingToDelete={personToDelete}
          onHide={() => setPersonToDelete(null)}
          disabled={running}
          message={`Remove ${
            personToDelete && personToDelete.name
          } from this schedule?`}
          onClick={deleteAssignment}
        />
        <PrimaryToast
          onClose={() => setToastMessage(null)}
          toastMessage={toastMessage}
        />
      </Middle>
      <Footer />
    </>
  );
};

const AdminSlot = () => {
  const { id } = useParams();
  const { data, mutate } = useSWR(`/admin/slots/${id}`);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const handlePersonChange = (e) => setSelectedPersonId(e.target.value);
  const api = useContext(APIContext);
  const [personToDelete, setPersonToDelete] = useState(null);
  const [running, setRunning] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const createAssignment = async () => {
    setRunning(true);
    const createdAssignment = await api.post(`/admin/slots/${id}/assignments`, {
      person: selectedPersonId,
    });
    await mutate();
    setRunning(false);
    setSelectedPersonId(null);
    setToastMessage(
      `${createdAssignment.data.name} was assigned to this slot.`
    );
  };
  const deleteAssignment = async () => {
    setRunning(true);
    const deletedAssignment = await api.delete(
      `/admin/assignments/${personToDelete.id}`
    );
    await mutate();
    setPersonToDelete(null);
    setRunning(false);
    setToastMessage(
      `${deletedAssignment.data.name} was removed from this slot.`
    );
  };
  return (
    <>
      <Header>
        <BackHomeButton to={`/admin/calendars/${data.calendar.slug}/slots`} />
        <div>{format(new Date(data.start), "hh:mmaaa @ do MMM yy")}</div>
      </Header>
      <Middle>
        {data.assignments.map((a) => (
          <LinedContainer key={a.id}>
            <div className="fs-6">{a.name}</div>
            <Button
              variant="danger"
              onClick={() => setPersonToDelete(a)}
              disabled={running}
              size="sm"
            >
              <i className="bi bi-x-lg" />
            </Button>
          </LinedContainer>
        ))}
        <PersonSelector
          person={selectedPersonId}
          onChange={handlePersonChange}
          people={data.people}
          onSubmit={createAssignment}
          running={running}
        />
      </Middle>
      <Footer />
      <DeleteConfirmationModal
        thingToDelete={personToDelete}
        onHide={() => setPersonToDelete(null)}
        disabled={running}
        message={`Remove ${
          personToDelete && personToDelete.name
        } from this slot?`}
        onClick={deleteAssignment}
      />
      <PrimaryToast
        onClose={() => setToastMessage(null)}
        toastMessage={toastMessage}
      />
    </>
  );
};

const AdminPerson = () => {
  const { id } = useParams();
  const { data, mutate } = useSWR(`/admin/people/${id}`);
  const api = useContext(APIContext);
  return (
    <>
      <Header>
        <BackHomeButton to={`/admin/calendars/${data.calendar.slug}/people`} />
        <div>{data.name}</div>
      </Header>
      <Middle>
        <LinedContainer key="form">
          <PersonForm
            data={data}
            mutate={mutate}
            action={(data) => api.put(`/admin/people/${id}`, data)}
          />
        </LinedContainer>
        {data.schedules.map((s) => (
          <LinedContainer key={s.id}>
            <ScheduleDisplay schedule={s} />
          </LinedContainer>
        ))}
      </Middle>
      <Footer />
    </>
  );
};

const FallbackComponent = (props) => {
  return (
    <div className="container my-5">
      <h3 className="text-danger">Oops.</h3>
      <p className="my-4">
        There was an error, and it's been reported to our systems. While we
        investigate, here's what you can try:
      </p>
      <div className="d-flex flex-column gap-3">
        <button
          className="btn btn-lg btn-success w-100 text-uppercase bg-gradient"
          onClick={() => window.location.reload()}
        >
          Reload this page
        </button>
        <button
          className="btn btn-warning w-100 text-uppercase bg-gradient"
          onClick={() => (window.location = "/")}
        >
          Go Back Home
        </button>
        <button
          className="btn bg-gradient btn-sm btn-danger w-100 text-uppercase"
          onClick={() => {
            localStorage.clear();
            window.location = "/";
          }}
        >
          Sign Out & Restart
        </button>
      </div>
    </div>
  );
};

const App = () => {
  const [token, setToken] = useLocalStorage("token", null);
  const login = (token) => setToken(token);
  const logout = () => setToken(null);

  if (!token) {
    return (
      <APIContext.Provider
        value={axios.create({
          baseURL: process.env.REACT_APP_ENDPOINT,
        })}
      >
        <LoginContext.Provider value={login}>
          <Home />
        </LoginContext.Provider>
      </APIContext.Provider>
    );
  }

  const authenticatedAxios = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
      Authorization: token,
    },
    params: {
      token: token,
    },
  });
  return (
    <APIContext.Provider value={authenticatedAxios}>
      <SWRConfig
        value={{
          fetcher: (url) => authenticatedAxios.get(url).then((res) => res.data),
          suspense: true,
        }}
      >
        <LogoutContext.Provider value={logout}>
          <ErrorBoundary
            fallback={(error) => <FallbackComponent />}
            instance={appsignal}
          >
            <Suspense
              fallback={
                <div className="d-flex min-vh-100 align-items-center justify-content-center">
                  <Spinner animation="border" />
                </div>
              }
            >
              <Router>
                <Switch>
                  <Route path="/admin/slots/:id">
                    <AdminSlot />
                  </Route>
                  <Route path="/admin/schedules/:id">
                    <AdminSchedule />
                  </Route>
                  <Route path="/admin/people/:id">
                    <AdminPerson />
                  </Route>
                  <Route path="/admin/calendars/:slug/slots">
                    <AdminSlots />
                  </Route>
                  <Route path="/admin/calendars/:slug/schedules">
                    <AdminSchedules />
                  </Route>
                  <Route path="/admin/calendars/:slug/people">
                    <AdminPeople />
                  </Route>
                  <Route path="/admin/calendars/:slug/history">
                    <AdminHistory />
                  </Route>
                  <Route path="/admin/calendars/:slug">
                    <AdminCalendar />
                  </Route>
                  <Route path="/admin/calendars">
                    <AdminCalendars />
                  </Route>
                  <Redirect from="/admin" to="/admin/calendars" />
                  <Route path="/:slug">
                    <Slots />
                  </Route>
                  <Route path="/">
                    <Dashboard />
                  </Route>
                </Switch>
              </Router>
            </Suspense>
          </ErrorBoundary>
        </LogoutContext.Provider>
      </SWRConfig>
    </APIContext.Provider>
  );
};

export default App;
