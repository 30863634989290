import { InputGroupLabel, MobileNumberInput, OTPInput } from "./atoms";
import React, { useContext, useState } from "react";
import { BottomFix } from "./molecules";
import { APIContext, LoginContext, LogoutContext } from "./App";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { LinedContainer } from "./pages";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap";

export const MobileLogin = () => {
  const [otpRequested, setOtpRequested] = useState(false);
  const [mobile, setMobile] = useState("");
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationRequested, setConfirmationRequested] = useState(false);
  const api = useContext(APIContext);
  const login = useContext(LoginContext);

  const reset = () => {
    setOtpRequested(false);
    setConfirmationRequested(false);
    setMobile("");
    setOtp("");
  };
  const requestOTP = async () => {
    setOtpRequested(true);
    const otpResponse = await api.post("/tokens", {
      mobile: mobile,
      countryCode: "IN",
    });
    setToken(otpResponse.data.token);
  };
  const confirmOTP = async () => {
    setConfirmationRequested(true);
    try {
      const confirmResponse = await api.patch(`/tokens/${token}`, {
        otp: otp,
      });
      if (confirmResponse.data.verified) {
        login(token);
      }
    } catch (e) {
      setConfirmationRequested(false);
      alert("Please double check the OTP and try again.");
    }
  };
  const cancel = () => {
    if (window.confirm("Would you like to stop the login process?")) {
      reset();
    }
  };
  return (
    <>
      <InputGroup className="my-3">
        <InputGroupLabel>+91</InputGroupLabel>
        <MobileNumberInput
          disabled={otpRequested}
          value={mobile}
          onChange={setMobile}
        />
        {!otpRequested && <Button onClick={requestOTP}>Login</Button>}
        {otpRequested && (
          <Button cancel={true} variant="danger" onClick={cancel}>
            <i className="bi bi-x-lg" />
          </Button>
        )}
      </InputGroup>
      {otpRequested && (
        <InputGroup className="my-3">
          <InputGroupLabel>OTP</InputGroupLabel>
          <OTPInput value={otp} onChange={setOtp} />
          <Button onClick={confirmOTP} disabled={confirmationRequested}>
            {(confirmationRequested && (
              <Spinner animation="border" size="sm" />
            )) || <>Confirm</>}
          </Button>
        </InputGroup>
      )}
    </>
  );
};
export const Footer = (props) => {
  const logout = useContext(LogoutContext);
  const confirmAndLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      logout();
    }
  };
  const { data } = useSWR("/tokens/current");

  return (
    <BottomFix>
      <div className="text-secondary lh-1">{data.phone}</div>
      {data.admin && (
        <Link
          to="/admin"
          className="link-dark text-decoration-none text-uppercase"
        >
          Admin
        </Link>
      )}
      <button
        onClick={confirmAndLogout}
        className="link-dark text-decoration-none text-uppercase text-danger border-0 bg-light px-0"
      >
        Logout
      </button>
    </BottomFix>
  );
};
export const PersonSelector = (props) => (
  <LinedContainer>
    <div className="input-group input-group-sm">
      <select
        className="form-select"
        value={props.person ? props.person.id : ""}
        onChange={props.onChange}
      >
        <option value="-1">Add a person...</option>
        {props.people.map((p) => (
          <option value={p.id} key={p.id}>
            {p.name}
          </option>
        ))}
      </select>
      <Button
        variant="outline-success"
        onClick={props.onSubmit}
        disabled={props.running || !props.person}
      >
        ADD
      </Button>
    </div>
  </LinedContainer>
);

export const PrimaryToast = (props) => (
  <ToastContainer position="bottom-end" className="p-3">
    <Toast
      onClose={props.onClose}
      show={!!props.toastMessage}
      delay={5000}
      autohide
      bg="primary"
    >
      <Toast.Body className="text-light">{props.toastMessage}</Toast.Body>
    </Toast>
  </ToastContainer>
);

export const DeleteConfirmationModal = (props) => (
  <Modal show={!!props.thingToDelete} size="lg" centered onHide={props.onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Are you sure?</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.message}</Modal.Body>
    <Modal.Footer>
      <Button variant="light" onClick={props.onHide} disabled={props.disabled}>
        OOPS!
      </Button>
      <Button
        variant="danger"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        REMOVE
      </Button>
    </Modal.Footer>
  </Modal>
);
export const PersonForm = (props) => {
  const [name, setName] = useState(props.data.name || "");
  const [phone, setPhone] = useState(props.data.phone || "");
  const [expiry, setExpiry] = useState(props.data.expiry || "");
  const [running, setRunning] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const updatePerson = async () => {
    setRunning(true);
    props
      .action({
        name: name,
        phone: phone,
        expiry: expiry,
      })
      .then((response) => {
        setToastMessage(`Saved details for ${response.data.name}.`);
        setRunning(false);
        props.mutate();
        if (props.autoClear) {
          setName("");
          setPhone("");
          setExpiry("");
        }
      })
      .catch(() => {
        alert("There was a problem saving these details.");
        setRunning(false);
      });
  };
  return (
    <div className="d-flex flex-column gap-3 flex-grow-1">
      <FloatingLabel label="Name">
        <Form.Control
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FloatingLabel>
      <FloatingLabel label="Mobile Number">
        <Form.Control
          type="tel"
          placeholder="Mobile"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
      </FloatingLabel>
      <FloatingLabel label="Expiry Date">
        <Form.Control
          type="date"
          placeholder="Expiry Date"
          onChange={(e) => setExpiry(e.target.value)}
          value={expiry}
        />
      </FloatingLabel>
      <Button variant="success" onClick={updatePerson} disabled={running}>
        Save
      </Button>
      <PrimaryToast
        onClose={() => setToastMessage(null)}
        toastMessage={toastMessage}
      />
    </div>
  );
};
