import classNames from "classnames";
import React from "react";
import { format } from "date-fns";
import { Container } from "react-bootstrap";

const margins = (props) => ({
  "my-5": props.m === 5,
  "my-4": props.m === 4,
  "my-3": props.m === 3,
  "my-2": props.m === 2,
  "my-1": props.m === 1,
});

export const HeroContainer = (props) => (
  <div className="my-5 border-top border-1 py-3 border-bottom bg-light">
    <Container>{props.children}</Container>
  </div>
);
export const BottomFix = (props) => (
  <div className="border-top bg-light d-flex align-items-center lh-1 border-bottom">
    <Container className="d-flex justify-content-between align-items-center small py-3">
      {props.children}
    </Container>
  </div>
);
export const FootnoteSignature = () => (
  <span className="text-secondary">
    Crafted by{" "}
    <a href="https://twitter.com/sudhirj" className="link-secondary">
      Sudhir Jonathan
    </a>
    .
  </span>
);
export const Header = (props) => (
  <div className="border-bottom bg-light border-top">
    <Container className="py-3">
      <div className="d-flex lh-1 fw-bold text-primary flex-row gap-2 align-items-center justify-content-between">
        {props.children}
      </div>
    </Container>
  </div>
);
export const Middle = (props) => (
  <div className="my-3 d-flex flex-column gap-3">{props.children}</div>
);
export const DateIndicator = (props) => {
  const date = new Date(props.date);
  return (
    <div
      className={classNames(
        "text-uppercase d-flex flex-column gap-1",
        props.className
      )}
    >
      <div className="fw-bold lh-1">{format(date, "iii")}</div>
      <small className="lh-1 text-muted opacity-75">
        {format(date, "dd MMM")}
      </small>
    </div>
  );
};
export const RatioDisplay = (props) => (
  <span style={{ fontVariantNumeric: "diagonal-fractions" }} {...props}>
    {`${props.num}/${props.denom}`}
  </span>
);
