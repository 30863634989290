import { Link } from "react-router-dom";
import React from "react";
import { format } from "date-fns";
import { Button } from "react-bootstrap";

export const SiteHeading = (props) => (
  <h1 className="text-center py-3">{props.children}</h1>
);

export const TimeRange = (props) => {
  const start = new Date(props.start);
  const finish = new Date(props.finish);
  return (
    <div className="d-flex flex-column gap-1">
      <div className="lh-1 fw-bold">{format(start, "hh:mmaaa")}</div>
      <small className="lh-1 text-muted fw-light opacity-75 text-end d-flex flex-row align-items-top gap-1">
        <i className="bi bi-arrow-return-right" />
        <span>{format(finish, "hh:mmaaa")}</span>
      </small>
    </div>
  );
};

export const MobileNumberInput = (props) => (
  <input
    disabled={props.disabled}
    type="tel"
    className="form-control"
    placeholder="9876543210"
    maxLength={10}
    pattern="[0-9]*"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  />
);

export const OTPInput = (props) => (
  <input
    disabled={props.disabled}
    type="text"
    className="form-control"
    placeholder="123456"
    maxLength={6}
    pattern="[0-9]*"
    inputMode="numeric"
    autoComplete="one-time-code"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  />
);

export const InputGroupLabel = (props) => (
  <label className="input-group-text">{props.children}</label>
);

export const IconButtonLink = (props) => (
  <Link to={props.to} className="">
    <Button variant={props.type} className="bg-gradient">
      {props.icon ? <i className={`bi bi-${props.icon}`} /> : props.children}
    </Button>
  </Link>
);
